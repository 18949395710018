body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rajdhani',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.headlineBox {
  height: "1em"
}
.node.person text {
  font-weight: bold;
  pointer-events: none;
}

.link {
  stroke: #999;
  stroke-opacity: .6;
}
.NavBarTop {
  background-color: "black"; /* For browsers that do not support gradients */
  background-image: repeating-linear-gradient(90deg, #f2f2f2 1px, white 3px, #d6d6d6 3px, white 5px, #f2f2f2 20px);
}
.active {
  color:"gray"
}
.inactive {
  color:"red"
}
.halftone {
  background-image: radial-gradient(
    circle at center,
    black 0.25rem,
    transparent 0
  ), radial-gradient(circle at center, black 0.25rem, transparent 0);
  background-size: 1.3rem 1.3rem;
  background-position: 0 0, 0.65rem 0.65rem;
}